import React from 'react';
import Sticky from 'react-stickynode';
import Navbar from './containers/CryptoModern/Navbar';
import Banner from './containers/CryptoModern/Banner';
import CountDown from './containers/CryptoModern/CountDown';
import Features from './containers/CryptoModern/FeatureSection';
import MapSection from './containers/CryptoModern/MapSection';
import FaqSection from './containers/CryptoModern/FaqSection';
import Newsletter from './containers/CryptoModern/Newsletter';
import {
  CryptoWrapper,
  ContentWrapper,
} from './containers/CryptoModern/cryptoModern.style';
import Image from "./components/Image";
import Logo from "./assets/images/chiron-logo.png";
import Container from "./components/UI/Container";

export default function () {
  return (
    <CryptoWrapper>
      <Sticky top={0} innerZ={9999} activeClass='sticky-active'>
        <Navbar />
      </Sticky>
      <ContentWrapper>
        <Banner />
        {/* 10/7/2020 Removed Countdown for now */}
        {/*<CountDown />*/}
      <Container>
      <Image
  src={Logo}
  alt="Map Image"
      style={{
    width: '500px',
    margin: '0 auto'
  }}
      />
      </Container>
       <Features />
        <MapSection />
        <FaqSection />
        <Newsletter />
      </ContentWrapper>
    </CryptoWrapper>
  );
}
