import React from 'react';
import Fade from 'react-reveal/Fade';
import Text from '../../../components/Text';
import Heading from '../../../components/Heading';
import Button from '../../../components/Button';
import Image from '../../../components/Image';
import Container from '../../../components/UI/Container';
import NormalClock from './timer';
import SectionWrapper, { ContentWrapper } from './countdown.style';

const deadline = new Date(Date.parse(new Date()) + 12 * 24 * 60 * 60 * 1000);

const CountDownSection = () => {
  return (
    <SectionWrapper>
      <Container>
        <ContentWrapper>
          <Heading content='DCs going live in: ' />
          <Fade up>
            <div className='timerCount'>
              <NormalClock countdown={deadline} divider='true' />
            </div>
          </Fade>
          {/*<Button className='primary' title='PRE-ORDER NOW' />*/}
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default CountDownSection;
