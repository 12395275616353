/* eslint-disable import/first */
import React from 'react';
/* ------------------------------------ */
// Navbar data section
/* ------------------------------------ */
import logo from '../assets/images/logo.png';

export const navbar = {
  logo: logo,
  navMenu: [
    {
      id: 1,
      label: 'Home',
      path: '#home',
      offset: '84',
    },
    {
      id: 2,
      label: 'Features',
      path: '#key-features',
      offset: '81',
    },
    {
      id: 3,
      label: 'FAQ',
      path: '#faqSection',
      offset: '81',
    },
    {
      id: 4,
      label: 'Server',
      path: '#server',
      offset: '81',
    },
  ],
};

/* ------------------------------------ */
// Discount
/* ------------------------------------ */

export const Discount = {
  amount: 10,
  code: 'CHIRON10'
}

/* ------------------------------------ */
// Links
/* ------------------------------------ */
export const Links = {
  dashboardLink: "https://chironproxies.com/dashboard",
  discordInvite: "https://discord.gg/EFh4pqK",
  proxyTester: "https://www.fogldn.com/proxy-tester/"
}

/* ------------------------------------ */
// Features data section
/* ------------------------------------ */
import featureIcon1 from '../assets/images/speed-128.png';
import featureIcon2 from '../assets/images/thumbs.png';
import featureIcon3 from '../assets/images/shop.png';
import featureIcon4 from '../assets/images/calendar.png';

export const Features = [
  {
    id: 1,
    icon: featureIcon1,
    title: 'Unmatched Speeds',
    description:
      'With even our resis pinging as low at 150ms, our speeds are the fastest on the market.',
  },
  {
    id: 2,
    icon: featureIcon2,
    title: 'Reliability',
    description:
      'We guarantee 99.99% uptime on all our products — and a positive experience every time you buy from us.',
  },
  {
    id: 3,
    icon: featureIcon3,
    title: '24/7 Discord Support',
    description:
      'Have a question or need support? Our committed staff will make sure it\'s resolved within minutes, not hours.',
  },
  {
    id: 4,
    icon: featureIcon4,
    title: 'Tailored Plans',
    description:
      'We can offer plans that are more customized to your needs and preferences. Inquire about it today in our Discord server!',
  },
];

/* ------------------------------------ */
// Faq  data section
/* ------------------------------------ */

export const Faq = [
  {
    id: 1,
    expend: true,
    title: 'Why choose Chiron Proxies?',
    description: 'Unlike most of our competitors, we provide both speed AND reliability. We’ve noticed most providers who offer reliable proxies typically don’t have good speeds. Likewise, we’ve noticed most providers who offer good speeds typically aren’t reliable and run into lot of down time. We’ve eliminated that issue.'
  },
  {
    id: 2,
    expend: true,
    title: 'What is a proxy and why do I need one to buy sneakers/limited items?',
    description:
      'A proxy, identified by an IP address, allows you to connect to a server which then acts as a gateway between you and the Internet. When using checkout automation software to buy sneakers or other limited items, these are needed as they allow you to run a ton of requests from multiple different locations, in turn heightening your chances of copping said sneakers or limited items. If you were to run all these instances on the same IP, it would get banned. ',
  },
  {
    id: 3,
    title: 'What’s the difference between Datacenter and Residential IPs?',
    description:
      'Datacenter proxies will perform relatively faster than Residentials, however they are less reliable and more likely to be banned by websites. You also pay per data usage. Residentials will perform slower, however they are much more reliable and less likely to be banned by websites. Here you pay per IP.',
  },
  {
    id: 4,
    title: 'How do I know which plan to purchase?',
    description:
      'Our current Essential Residential plan is optimized for Footsites, Hibbett, Yeezy Supply, Adidas US, SNKRS & Shopify (not guaranteed on bot protection). Once we introduce new products, we will provide details on those as well. ',
  },
  {
    id: 5,
    title: 'Where can I test my proxies?',
    description:
      `Many proxy testers are highly unreliable. We suggest you download and use Fogldn. You can download for free <a href="${Links.proxyTester}">here</a>.`,
  },
];
