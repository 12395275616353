import React from 'react';
import Fade from 'react-reveal/Fade';

import Text from '../../../components/Text';
import Image from '../../../components/Image';
import Button from '../../../components/Button';
import Heading from '../../../components/Heading';
import Container from '../../../components/UI/Container';
import BannerWrapper, {
  BannerContent,
  DiscountLabel,
  BannerImage,
  ButtonGroup,
} from './banner.style';

import {Discount, Links} from '../../../data/index';

import bannerImg from '../../../assets/images/banner-bg.png';

const Banner = () => {
  return (
    <BannerWrapper id="home">
      <Container>
        <BannerContent>
          <Fade up>
            <DiscountLabel>
              <Text className="discountAmount" content={`${Discount.amount}% Discount`} />
              <Text
                className="discountText"
                content={`is now active: ${Discount.code}`}
              />
            </DiscountLabel>
          </Fade>
          <Fade up delay={100}>
            <Heading
              as="h1"
              content="Your high-end AIO sneaker proxy provider"
            />
          </Fade>
          <Fade up delay={200}>
            <Text
              content="Tired of buying proxies that don't meet your standards? We currently provide the fastest, most reliable residential proxies on the market. Consistently seeing success across all platforms, these are sure to help fill up your checkout logs in no time."
            />
          </Fade>
          <Fade up delay={300}>
            <ButtonGroup>
              <Button
                className="primary"
                title="PURCHASE"
                href={Links.dashboardLink}
                as="a"
                    />
              <Button
                className="text"
                variant="textButton"
                title="JOIN SERVER"
                as="a"
                href={Links.discordInvite}
              />
            </ButtonGroup>
          </Fade>
        </BannerContent>
        <BannerImage>
          <Fade in delay={100}>
            <Image src={bannerImg} alt="Banner" />
          </Fade>
        </BannerImage>
      </Container>
    </BannerWrapper>
  );
};

export default Banner;
